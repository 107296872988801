<template>
    <div class="stati">
      <div class="stati-item">
        <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
  
        <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
          <el-table-column prop="key" label="基本设置"> 
            <template slot-scope="scope">
            {{ scope.row.key == "room_notice" ? "房间公告" 
            : scope.row.key == "room_fault" ? "房间故障" 
            : scope.row.key == "game_rule" ? "游戏规则" 
            : scope.row.key == "audio_url" ? "音频路径" 
            : scope.row.key == "invite_amount" ? "邀请奖励" 
            : scope.row.key == "default_logo" ? "LOGO" 
            : scope.row.key == "charges_explain2" ? "连续充值介绍" 
            : scope.row.key == "share_desc" ? "APP分享介绍" 
            : scope.row.key == "share_url" ? "分享地址" 
            : scope.row.key == "promotion_one_amount" ? "主播邀请单人奖励"
            : scope.row.key == "promotion_charge_bill" ? "主播邀请充值奖励（比例）"
            : scope.row.key == "download_android" ? "安卓下载"
            : scope.row.key == "download_ios" ? "IOS下载"
            : scope.row.key == "fix_mobile" ? "联系电话"
            : scope.row.key == "fix_mail" ? "联系邮箱"
            : " " }}
          </template>
          </el-table-column>
          <el-table-column prop="value" label="内容"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-dropdown
                size="mini"
                @command="(command) => getOperation(command, scope.row)"
              >
                <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                  >更多</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="update" icon="el-icon-circle-check"
                    >修改</el-dropdown-item
                  >
                 
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
       
  
        <!-- 弹窗 -->
        <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
          <el-form :model="info">
            <el-form-item label="基本设置">
              <el-input v-model="info.key" autocomplete="off" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="内容">
              <el-input v-model="info.value" autocomplete="off"></el-input>
            </el-form-item>
            
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="getUpdateConfig()"
              >确 定 修 改</el-button
            >
          </div>
        </el-dialog>
  
       
      </div>
    </div>
  </template>
    <script>
  import {
    configListApi,
    updateConfigApi,
    getConfigApi,
  } from "@/api/site/setting"
  export default {
    name: "menu-item",
    data() {
      return {
        dialogFormVisible: false, //弹窗
        info: {
          id: "",
        },
        tableData: [{}],
      };
    },
  
    mounted() {
      document.title = '后台管理系统|'+this.$route.meta.title ;
      this.getConfigList();
    },
    watch: {
      '$route': function(to) {
        document.title = '后台管理系统|'+to.meta.title ;
      }
    },
    methods: {
      cellStyle() {
      return {'text-align': 'center'};
    },
      //文件上传
      handleAvatarSuccess(res) {
          this.info.imageUrl = res.data.url;
        },
      //修改
      getUpdateConfig() {
        this.dialogFormVisible = false;
        updateConfigApi(this.info).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              message: "数据修改成功",
              type: "success",
            });
            this.getConfigList();
          }
        });
      },
      //回显
      getConfig(id) {
        this.dialogFormVisible = true;
        getConfigApi(id).then((res) => {
          if (res.data.code == 200) {
            this.info = res.data.data;
            this.$notify({
              message: "数据显示成功",
              type: "success",
            });
          }
        });
      },
      
      getFlushed(){
      this.getConfigList();
    },
      //列表
      getConfigList() {
        configListApi().then((res) => {
          this.tableData = res.data.data;
        });
      },
  
     
      //操作功能
      getOperation(command, row) {
        switch (command) {
          case "update":
            //回显
            this.getConfig(row.id);
            break;
         
          default:
            break;
        }
      },
    },
  };
  </script>
    
    <style scoped>
  .el-card {
    /* margin-bottom: 20px; */
    text-align: center;
    margin-left: 60px;
  }
  .stati-item {
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 40px;
  }
  .stati {
    width: 100%;
    height: 100%;
  }
  .el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
  </style>