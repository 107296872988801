// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

export const userList = (data) => {
    return service({
        url: '/userInfo/tuserVoList',
        method: 'post',
        data,
       
    })
};

export const updateUserApi = (data) => {
    return service({
        url: '/userInfo/updateUser',
        method: 'put',
        data
    })
};

export const deleteUserApi = (id) => {
    return service({
        url: '/userInfo/deleteUser/'+id,
        method: 'put',
        
    })
};

export const UserById = (id) => {
    return service({
        url: '/userInfo/getUserById/'+id,
        method: 'get',
        
    })
};

// 账号充值
export const accountApi = (data) => {
    return service({
        url: '/userInfo/accountReCharge',
        method: 'put',
        data
    })
};
//金币充值
export const goldApi = (data) => {
    return service({
        url: '/userInfo/goldReCharge',
        method: 'put',
        data
    })
};
