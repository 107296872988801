<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
        <el-form-item label="手机号" prop="roleName">
          <el-input
            v-model="form.mobile"
            placeholder="请输入手机号"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="邀请码" prop="roleKey">
          <el-input
            v-model="form.inviteCode"
            placeholder="请输入邀请码"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getVersionList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addVersionLog"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="inviteCode" label="邀请码"> </el-table-column>
        <el-table-column
          prop="downloadUrl"
          label=" 下载URL"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="fileSize" label="安装包大小"> </el-table-column>
        <el-table-column prop="fileVersion" label="安装版本号">
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column prop="deletedAt" label="删除时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="手机号">
            <el-input v-model="info.mobile" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邀请码">
            <el-input v-model="info.inviteCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="下载URL">
            <el-input v-model="info.downloadUrl" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="安装包大小">
            <el-input v-model="info.fileSize" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="安装版本号">
            <el-input v-model="info.fileVersion" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateVersion()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="手机号">
            <el-input v-model="info.mobile" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邀请码">
            <el-input v-model="info.inviteCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="下载URL">
            <el-input v-model="info.downloadUrl" autocomplete="off"></el-input>
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="info.downloadUrl" :src="info.downloadUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="安装包大小">
            <el-input v-model="info.fileSize" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="安装版本号">
            <el-input v-model="info.fileVersion" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getaddVersion()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
  <script>
import {
  appVersionList,
  delappVersion,
  updateappVersion,
  getappVersion,
  addappVersion,
} from "@/api/site/app"
export default {
  name: "menu-item",
  data() {
    return {
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        inviteCode: "",
        mobile: "",
        pageSize: 20,
        pageNum: 1,
      },
      info: {
        id: "",
      },
      tableData: [{}],
    };
  },

  mounted() {
    document.title = '后台管理系统|'+this.$route.meta.title ;
    this.getVersionList();
  },
  watch: {
    '$route': function(to, from) {
      document.title = '后台管理系统|'+to.meta.title ;
    }
  },
  methods: {
    //文件上传
    handleAvatarSuccess(res, file) {
        this.info.imageUrl = res.data.url;
      },
      beforeAvatarUpload(file) {
        const fileType = file.type === 'image';
        if (!fileType) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        return fileType;
      },
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = {
      };
    },
    //添加方法
    getaddVersion() {
      this.dialogVisible = false;
      addappVersion(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "数据添加成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },

    //删除
    getDelVersion(id) {
      delappVersion(id).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },
    //修改
    getUpdateVersion() {
      this.dialogFormVisible = false;
      updateappVersion(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "数据修改成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },
    //回显
    getInfoVersion(id) {
      this.dialogFormVisible = true;
      getappVersion(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$message({
            message: "数据显示成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },
    //列表
    getVersionList() {
      appVersionList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getVersionList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getVersionList();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getInfoVersion(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelVersion(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
  
  <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 90px;
}
.stati {
  margin-left: 30px;
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>