// file:api.js
// 1、导入axios以及拦截器
import service from '../request'


export const configListApi = () => {
    return service({
        url: '/config/configList',
        method: 'post',
        
    })
};



export const updateConfigApi = (data) => {
    return service({
        url: '/config/configUpdate',
        method: 'put',
        data
    })
};
export const getConfigApi = (id) => {
    return service({
        url: '/config/getConfig/'+id,
        method: 'get',
    })
};