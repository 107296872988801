// file:api.js
// 1、导入axios以及拦截器
import service from '../request'



export const articleListApi = (data) => {
    return service({
        url: '/article/articleList',
        method: 'post',
        data
    })
};

export const addArticleApi = (data) => {
    return service({
        url: '/article/addArticle',
        method: 'post',
        data
    })
};

export const updateArticleApi = (data) => {
    return service({
        url: '/article/articleUpdate',
        method: 'put',
        data
    })
};
export const getArticleApi = (id) => {
    return service({
        url: '/article/getArticle/'+id,
        method: 'get',
    })
};

export const delArticleApi = (id) => {
    return service({
        url: '/article/delArticle/'+id,
        method: 'put',
    })
};