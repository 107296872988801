// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

export const userSignApi = (data) => {
    return service({
        url: '/userInfo/userSignList',
        method: 'post',
        data
    })
};