// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const marketLockList = (data) => {
    return service({
        url: '/combo/marketLockList',
        method: 'post',
        data
    })
};

//修改
export const marketLockUpdate = (data) => {
    return service({
        url: '/combo/marketLockUpdate',
        method: 'put',
        data
        
    })
};

//回显
export const getMarketLock = (id) => {
    return service({
        url: '/combo/getMarketLock/'+id,
        method: 'get',
    })
};

//添加
export const addMarketLock = (data) => {
    return service({
        url: '/combo/addMarketLock',
        method: 'post',
        data
    })
};

//删除
export const delMarketLock = (id) => {
    return service({
        url: '/combo/delMarketLock/'+id,
        method: 'put',
    })
};