// file:api.js
// 1、导入axios以及拦截器
import service from '../request'
//列表
export const charge2ListApi = (data) => {
    return service({
        url: '/marketingManage/marketCharge2List',
        method: 'post',
        data
    })
};

export const addChargeApi = (data) => {
    return service({
        url: '/marketingManage/addMarketCharge2',
        method: 'post',
        data
    })
};