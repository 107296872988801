<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addVersionLog"
        >添加</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="versionName" label="版本名称"> </el-table-column>
        <el-table-column prop="versionCode" label="版本号"> </el-table-column>
        <el-table-column prop="updateStatus" label="更新性质">
          <template slot-scope="scope">
            {{
              scope.row.updateStatus == 0
                ? "不需要"
                : scope.row.updateStatus == 1
                ? "非强制"
                : scope.row.updateStatus == 2
                ? "强制"
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column prop="appSystem" label="平台"> </el-table-column>
        <el-table-column prop="description" label="更新内容"> </el-table-column>
        <el-table-column prop="url" label="下载地址"> </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="版本名称">
            <el-input v-model="info.versionName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="版本">
            <el-input v-model="info.versionCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性质">
            <el-radio-group v-model="info.updateStatus">
              <el-radio :label="0">不需要</el-radio>
              <el-radio :label="1">非强制</el-radio>
              <el-radio :label="2">强制</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="平台">
            <el-radio-group v-model="info.appSystem">
              <el-radio label="Android">安卓</el-radio>
              <el-radio label="ios">IOS</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="更新内容">
            <el-input v-model="info.description" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="下载URL">
            <el-input v-model="info.url" autocomplete="off"></el-input>
            <el-upload
              class="upload-demo"
              :action=this.fileUrl
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateVersion()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="版本名称">
            <el-input v-model="info.versionName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="版本">
            <el-input v-model="info.versionCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性质">
            <el-radio-group v-model="info.updateStatus">
              <el-radio label="0">不需要</el-radio>
              <el-radio label="1">非强制</el-radio>
              <el-radio label="2">强制</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="平台">
            <el-radio-group v-model="info.appSystem">
              <el-radio label="Android">安卓</el-radio>
              <el-radio label="ios">IOS</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="更新内容">
            <el-input v-model="info.description" autocomplete="off"></el-input>
          </el-form-item>
          <!-- =================================================================== -->
          <el-form-item label="下载URL">
            <el-input v-model="info.url" autocomplete="off"></el-input>
            <el-upload
              class="upload-demo"
              :action=this.fileUrl
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <!-- ======================================================================= -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getaddVersion()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
    <script>
import {
  appVersionList,
  delappVersion,
  updateappVersion,
  getappVersion,
  addappVersion,
} from "@/api/site/app";
export default {
  name: "menu-item",
  data() {
    return {
      fileUrl:"",
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        inviteCode: "",
        mobile: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        id: "",
        url: "",
        updateStatus: "",
      },
      tableData: [{}],
      fileList: [{}],
    };
  },
  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFiles";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getVersionList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },

    //文件上传
    handleAvatarSuccess(res) {
      this.info.url = res.data;
    },

   
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = { url: "" };
    },
    //添加方法
    getaddVersion() {
      this.dialogVisible = false;
      addappVersion(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },

    //删除
    getDelVersion(id) {
      delappVersion(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },
    //修改
    getUpdateVersion() {
      this.dialogFormVisible = false;
      updateappVersion(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getVersionList();
        }
      });
    },
    //回显
    getInfoVersion(id) {
      this.dialogFormVisible = true;
      getappVersion(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getVersionList();
    },
    //列表
    getVersionList() {
      appVersionList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getVersionList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getVersionList();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getInfoVersion(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelVersion(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
    
    <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>