// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const taskDayList = (data) => {
    return service({
        url: '/marketingManage/taskDayList',
        method: 'post',
        data
    })
};

//修改
export const taskDayUpdate = (data) => {
    return service({
        url: '/marketingManage/taskDayUpdate',
        method: 'put',
        data
        
    })
};

//回显
export const getTaskDay = (id) => {
    return service({
        url: '/marketingManage/getTaskDay/'+id,
        method: 'get',
    })
};