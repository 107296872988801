import Vue from "vue";
import Router from "vue-router";
import index from "@/pages/index";
import fault from "@/pages/fault/fault";
import appVersion from "@/pages/site/appVersion";
import user from "@/pages/user/user";
import sign from "@/pages/user/sign";
import order from "@/pages/user/order";
import reward from "@/pages/user/reward";
import coins from "@/pages/user/coins";
import gameRoom from "@/pages/game/room";
import contract from "@/pages/game/contract";
import lock from "@/pages/game/lock";
import play from "@/pages/game/play";
import coin from "@/pages/game/coin";
import marketContract from "@/pages/game/marketContract";
import marketLock from "@/pages/game/marketLock";
import barrage from "@/pages/game/barrage";
import devil from "@/pages/game/devil";
import level from "@/pages/market/level";
import taskDay from "@/pages/market/task-day";
import taskLong from "@/pages/market/task-long";
import charge from "@/pages/market/charge";
import charge2 from "@/pages/market/charge2";
import rank from "@/pages/market/rank";
import marketSign from "@/pages/market/sign";
import login from "@/pages/login/login";
import article from "@/pages/site/article";
import setting from "@/pages/site/setting";
import banner from "@/pages/site/banner";
import app from "@/pages/site/app";
import feedback from "@/pages/site/feedback";
Vue.use(Router);

// eslint-disable-next-line no-unused-vars
let router;
// eslint-disable-next-line no-unused-vars
export default router = new Router({
  mode: "hash",
  routes: [
   
    {
      path: '/',
      component: login,
    },
	
    {
      path: "/index",
      name: "index-item",
      component: index,
      children: [
        {path:'/fault',component: fault,
          meta: {
          title: '机器故障管理'
          }
        },
        
        {path:'/site/appVersion',
          component: appVersion,
          meta: {
          title: 'app推广版本'
          }
        },
        {path:'/user/user',component: user,
          meta: {
          title: '用户管理'
          }
        },
        {path:'/user/order',component: order,
          meta: {
          title: '充值订单管理'
          }
        },
        {path:'/user/sign',component: sign,
          meta: {
          title: '用户签到管理'
          }
        },
        {path:'/user/reward',component: reward,
          meta: {
          title: '主播奖励管理'
          }
        },
        {path:'/user/coins',component: coins,
          meta: {
          title: '用户金币管理'
          }
        },
        {path:'/game/room',component: gameRoom,
          meta: {
          title: '游戏房间管理'
          }
        },
        {path:'/game/contract',component: contract,
          meta: {
          title: '游戏包机管理'
          }
        },
        {path:'/game/devil',component: devil,
          meta: {
          title: '魔鬼城房间'
          }
        },
        {path:'/game/lock',component: lock,
          meta: {
          title: '游戏锁机管理'
          }
        },
        {path:'/game/play',component: play,
          meta: {
          title: '游戏记录管理'
          }
        },
        {path:'/game/coin',component: coin,
          meta: {
          title: '游戏投币管理'
          }
        },
        {path:'/game/barrage',component: barrage,
          meta: {
          title: '房间弹幕管理'
          }
        },
        {path:'/game/marketContract',component: marketContract,
          meta: {
          title: '包机套餐管理'
          }
        },
        {path:'/game/marketLock',component: marketLock,
          meta: {
          title: '锁机套餐管理'
          }
        },





       


        {path:'/market/level',component: level,
          meta: {
          title: '会员等级管理'
          }
        },
        {path:'/market/task-day',component: taskDay,
          meta: {
          title: '每日任务管理'
          }
        },
        {path:'/market/task-long',component: taskLong,
          meta: {
          title: '长期任务管理'
          }
        },
        {path:'/market/charge',component: charge,
          meta: {
          title: '充值套餐管理'
          }
        },
        {path:'/market/charge2',component: charge2,
          meta: {
          title: '连续充值管理'
          }
        },
        {path:'/market/rank',component: rank,
          meta: {
          title: '排名奖励管理'
          }
        },
        {path:'/market/sign',component: marketSign,
          meta: {
          title: '签到奖励管理'
          }
        },
// 站点管理
        {path:'/site/article',component: article,
          meta: {
          title: '文章管理'
          }
        },
        {path:'/site/setting',component: setting,
          meta: {
          title: '参数设置'
          }
        },
        {path:'/site/banner',component: banner,
          meta: {
          title: '轮播图'
          }
        },
        {path:'/site/app',component: app,
          meta: {
          title: 'app'
          }
        },
        {path:'/site/feedback',component: feedback,
          meta: {
          title: '意见反馈'
          }
        },
      ]
        


    },
  ],
});
