// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const chargeListApi = (data) => {
    return service({
        url: '/marketingManage/marketChargeList',
        method: 'post',
        data
    })
};

export const addChargeApi = (data) => {
    return service({
        url: '/marketingManage/addMarketCharge',
        method: 'post',
        data
    })
};

export const updateChargeApi = (data) => {
    return service({
        url: '/marketingManage/marketChargeUpdate',
        method: 'put',
        data
    })
};
export const getMarketChargeApi = (id) => {
    return service({
        url: '/marketingManage/getMarketCharge/'+id,
        method: 'get',
    })
};

export const delMarketChargeApi = (id) => {
    return service({
        url: '/marketingManage/delMarketCharge/'+id,
        method: 'put',
    })
};