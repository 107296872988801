<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addVersionLog"
        >添加</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="amount" label="累计金额"> </el-table-column>
        <el-table-column prop="rate" label="兑换比例"> </el-table-column>
        <el-table-column prop="coins" label="升级奖励"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      
          <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="累计金额">
            <el-input v-model="info.amount" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="兑换比例">
            <el-input v-model="info.rate" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="赠送比例">
            <el-input v-model="info.giveRate" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否可发弹幕">
            <el-radio-group v-model="info.canBarrage">
              <el-radio :label="1">可以</el-radio>
              <el-radio :label="2">不可以</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="最高可进房间倍数">
            <el-input v-model="info.roomMultiple" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否有欢迎语">
            <el-radio-group v-model="info.welcome">
              <el-radio :label="1">有</el-radio>
              <el-radio :label="2">没有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="房间进入等待延时">
            <el-input v-model="info.roomDelay" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="免费锁机时长">
            <el-input v-model="info.lockTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="每天赠送金币数量">
            <el-input v-model="info.daysCoin" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="升级奖励">
            <el-input v-model="info.coins" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getlevelUpdate()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
         
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="累计金额">
            <el-input v-model="info.amount" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="兑换比例">
            <el-input v-model="info.rate" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="赠送比例">
            <el-input v-model="info.giveRate" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否可发弹幕">
            <el-radio-group v-model="info.canBarrage">
              <el-radio :label="1">可以</el-radio>
              <el-radio :label="2">不可以</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="最高可进房间倍数">
            <el-input v-model="info.roomMultiple" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否有欢迎语">
            <el-radio-group v-model="info.welcome">
              <el-radio :label="1">有</el-radio>
              <el-radio :label="2">没有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="房间进入等待延时">
            <el-input v-model="info.roomDelay" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="免费锁机时长">
            <el-input v-model="info.lockTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="每天赠送金币数量">
            <el-input v-model="info.daysCoin" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="升级奖励">
            <el-input v-model="info.coins" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getaddMarketLevel()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
        <script>
import {
  levelList,
  delMarketLevel,
  addMarketLevel,
  levelUpdate,
  getMarketLevel,
} from "@/api/market/level";
export default {
  name: "menu-item",
  data() {
    return {
      num: "",
      total: 0,

      info: {
        id: "",
      },
      form:{
        id: "",
        pageSize: 25,
        pageNum: 1,
      },
      tableData: [{}],
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
    };
  },

  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getLevelList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.imageUrl = res.data.url;
    },
    beforeAvatarUpload(file) {
      const fileType = file.type === "image";
      if (!fileType) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return fileType;
    },
    //删除
    getdelRoomBarrage(id) {
      delMarketLevel(id).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getLevelList();
        }
      });
    },
    getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getLevelList();
    },
    //列表
    getLevelList() {
      levelList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getLevelList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getLevelList();
    },

    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          this.getMarketLevelById(row.id);
          //回显
          break;
        case "deleted":
          //状态正常
          this.getdelRoomBarrage(row.id);
          break;
        default:
          break;
      }
    },

    //修改
    getlevelUpdate() {
      this.dialogFormVisible = false;
      levelUpdate(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "数据修改成功",
            type: "success",
          });
          this.getLevelList();
        }
      });
    },
    //回显
    getMarketLevelById(id) {
      this.dialogFormVisible = true;
      getMarketLevel(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$message({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = {};
    },
    //添加方法
    getaddMarketLevel() {
      this.dialogVisible = false;
      addMarketLevel(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "数据添加成功",
            type: "success",
          });
          this.getLevelList();
        }
      });
    },
  },
};
</script>
        
        <style scoped>
.el-card {
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 90px;
}
.stati {
  margin-left: 30px;
  width: 100%;
  height: 100%;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}

</style>