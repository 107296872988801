// file:api.js
// 1、导入axios以及拦截器
import service from '../request'
//列表
export const appVersionList = (data) => {
    return service({
        url: '/version/getVersionList',
        method: 'post',
        data
    })
};
//删除
export const delappVersion = (id) => {
    return service({
        url: '/version/delVersion/'+id,
        method: 'get',
    })
};
//修改
export const updateappVersion = (data) => {
    return service({
        url: '/version/updateVersion',
        method: 'post',
        data
    })
};
//回显
export const getappVersion = (id) => {
    return service({
        url: '/version/getVersion/'+id,
        method: 'get',
    })
};
//添加
export const addappVersion = (data) => {
    return service({
        url: '/version/addVersion',
        method: 'post',
        data
    })
};