<template>
    <div class="stati">
      <div class="stati-item">
        <el-form :model="form" size="small" :inline="true">
          <el-form-item label="" prop="roleName">
            ID<el-input
              v-model="form.id"
              placeholder="请输入ID"
              clearable
              style="width: 240px"
            />
          </el-form-item>
          <el-form-item label="类型" prop="roleKey">
              <el-radio v-model="form.type" :label="1">反馈</el-radio>
              <el-radio v-model="form.type" :label="2">故障</el-radio>
              <el-radio v-model="form.type" :label="3">维修中</el-radio>
              <el-radio v-model="form.type" :label="4">维修完成</el-radio>
              <el-radio v-model="form.type" :label="5">状态正常</el-radio>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getfeedbackList"
              >搜索</el-button
            >
          </el-form-item>
          <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
        </el-form>
  
        <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="uid" label="UID"> </el-table-column>
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
            {{ scope.row.type == 1 ? "反馈":
            scope.row.type == 2 ? "故障":
            scope.row.type == 3 ? "维修中":
            scope.row.type == 4 ? "维修完成":
            scope.row.type == 5 ? "状态正常":
            "" }}
          </template>   
          </el-table-column>
          <el-table-column prop="content" label="内容" show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="images" label="图片">
            <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.images"
            ></el-image>
          </template>
          </el-table-column>
          <el-table-column prop="status" label="是否已读"> 
            <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
             :active-value="0"
              :inactive-value="1"
              active-color="#02538C"
              inactive-color="#B9B9B9"
            ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-dropdown
                size="mini"
                @command="(command) => getOperation(command, scope.row)"
              >
                <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                  >更多</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  
                  <el-dropdown-item command="deleted" icon="el-icon-user"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="form.pageSize"
            layout="total,  prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
  
       
      </div>
    </div>
  </template>
    <script>
  import {
    feedbackListApi,
    delFeedbackApi,
  } from "@/api/site/feedback"
  export default {
    name: "menu-item",
    data() {
      return {
        total: 0,
        form: {
          inviteCode: "",
          mobile: "",
          pageSize: 10,
          pageNum: 1,
          
        },
        info: {
          id: "",
        },
        tableData: [{}],
      };
    },
  
    mounted() {
      document.title = '后台管理系统|'+this.$route.meta.title ;
      this.getfeedbackList();
    },
    watch: {
      '$route': function(to) {
        document.title = '后台管理系统|'+to.meta.title ;
      }
    },
    methods: {
      cellStyle() {
      return {'text-align': 'center'};
    },
      //文件上传
      handleAvatarSuccess(res) {
          this.info.imageUrl = res.data;
        },
        // beforeAvatarUpload(file) {
        //   const fileType = file.type === 'image';
        //   if (!fileType) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!');
        //   }
        //   return fileType;
        // },
     
  
      //删除
      getdelFeedback(id) {
        delFeedbackApi(id).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getfeedbackList();
          }
        });
      },
      getFlushed(){
      this.form={},
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getfeedbackList();
    },
      //列表
      getfeedbackList() {
        feedbackListApi(this.form).then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
      },
  
      //分页
      handleSizeChange(val) {
        this.form.pageSize = val;
        this.getfeedbackList();
      },
      handleCurrentChange(val) {
        this.form.pageNum = val;
        this.getfeedbackList();
      },
      //操作功能
      getOperation(command, row) {
        switch (command) {
        
          case "deleted":
            //状态正常
            this.getdelFeedback(row.id);
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
    
    <style scoped>
  .el-card {
    /* margin-bottom: 20px; */
    text-align: center;
    margin-left: 60px;
  }
  .stati-item {
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 40px;
  }
  .stati {
    width: 100%;
    height: 100%;
  }
  .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
      
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .radio,
.el-button {
  margin-left: 20px;
  margin-top: 10px;
}
  </style>