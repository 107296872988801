// file:api.js
// 1、导入axios以及拦截器
import service from '../request'
export const bannerListApi = (data) => {
    return service({
        url: '/banner/bannerList',
        method: 'post',
        data
    })
};

export const addBannerApi = (data) => {
    return service({
        url: '/banner/addBanner',
        method: 'post',
        data
    })
};

export const updateBannerApi = (data) => {
    return service({
        url: '/banner/bannerUpdate',
        method: 'put',
        data
    })
};
export const getBannerApi = (id) => {
    return service({
        url: '/banner/getBanner/'+id,
        method: 'get',
    })
};

export const delBannerApi = (id) => {
    return service({
        url: '/banner/delBanner/'+id,
        method: 'put',
    })
};