// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //游戏记录管理列表

//列表
export const gamePlayList = (data) => {
    return service({
        url: '/gameRoom/gamePlayList',
        method: 'post',
        data
    })
};