<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="title" label="名称"> </el-table-column>
        <el-table-column prop="subtitle" label="副标题"> </el-table-column>
        <el-table-column prop="multiple" label="倍数"> </el-table-column>
        <el-table-column prop="image" label="封面">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.image"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="lookers" label="观看人数"> </el-table-column>
        <el-table-column prop="gameUid" label="游戏用户"> </el-table-column>
        <el-table-column prop="isNew" label="是否新区">
          <template slot-scope="scope">
            {{ scope.row.isNew == 1 ? "是" : scope.row.isNew == 0 ?"否":"" }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.status == -1
                ? "故障"
                : scope.row.status == 1
                ? "空闲"
                : scope.row.status == 0
                ? "离线"
                : "使用"
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
                <el-dropdown-item command="market_contract" icon="el-icon-user"
                  >包机套餐</el-dropdown-item
                >
                <el-dropdown-item command="market_lock" icon="el-icon-user"
                  >锁机套餐</el-dropdown-item
                >
                <el-dropdown-item command="game_play" icon="el-icon-user"
                  >游戏记录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="型号">
            <el-radio-group v-model="info.type">
              <el-radio :label="1">推币机</el-radio>
              <el-radio :label="2">魔鬼城</el-radio>
              <el-radio :label="3">娃娃机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="info.subtitle" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="图片">
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.image" :src="info.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="倍数">
            <el-input v-model="info.multiple" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="是否新区">
            <el-radio-group v-model="info.isNew">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="等级限制">
            <el-input v-model="info.levelCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="DeviceName">
            <el-input v-model="info.deviceName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Live Url">
            <el-input v-model="info.liveUrl" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="info.type">
              <el-radio :label="-1">故障</el-radio>
              <el-radio :label="0">离线</el-radio>
              <el-radio :label="1">空闲</el-radio>
              <el-radio :label="2">使用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getupdateGameRoom()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
      <script>
import {
  GameRoomList,
  delGameRoom,
  updateGameRoom,
  getGameRoom,
} from "@/api/game/room";
export default {
  name: "menu-item",
  data() {
    return {
      fileUrl:"",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      tableData: [{}],
      dialogFormVisible: false,
      isShow: 1,
      status: '',
      info: {
        id: "",
      },
    };
  },
  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFile";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getGameRoomList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.image = res.data.url;
    },
   
    //修改
    getupdateGameRoom() {
      this.dialogFormVisible = false;
      updateGameRoom(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getGameRoomList();
        }
      });
    },
    //回显
    getGameRoom(id) {
      this.dialogFormVisible = true;
      getGameRoom(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    //删除
    getdelGameRoom(id) {
      delGameRoom(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getGameRoomList();
        }
      });
    },
    getFlushed(){
       
       this.getGameRoomList();
     },
    //列表
    getGameRoomList() {
      GameRoomList(this.pageNum, this.pageSize,this.isShow,this.status).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGameRoomList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getGameRoomList();
    },
    getmarketContract(multiple){
      this.$router.push({path: '/game/marketContract?multiple='+multiple});
    },

    getmarketLock(multiple){
      this.$router.push({path: '/game/marketLock?multiple='+multiple});
    },
    
    getgamePlay(id){
      this.$router.push({path: '/game/play?id='+id});
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          this.getGameRoom(row.id);
          //回显
          break;
        case "deleted":
          //状态正常
          this.getdelGameRoom(row.id);
          break;
          case "market_contract":
          //包机
          this.getmarketContract(row.multiple);
          break;
          case "market_lock":
          //锁机
          this.getmarketLock(row.multiple);
          break;
          case "game_play":
          //记录
          this.getgamePlay(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
      
<style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 90px;
}
.stati {
  margin-left: 30px;
  width: 100%;
  height: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>