// file:request.js
// 1、引入axios库
import axios from "axios"
import router from "@/router"


// 2、创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,  // api的base_url  
 
    timeout: 15000  // 请求超时时间
})

// 3、请求拦截器
service.interceptors.request.use(
    config => {
        // 获取token，如果有token，就放入http的请求头里面
        //======================================================================
        let token = localStorage.getItem('token');
        config.headers['User-Token']= token;
        //======================================================================
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 4、响应拦截器
service.interceptors.response.use(
    response => {

       
        
        //======================================================== 
        if(response.data.code==200112){
            router.push("/")
        }
        //========================================================
        let token = sessionStorage.getItem('token');    
        if (token==null&&token=="undefined") {
            // 重定向到登录页
            router.push("/")    
          }
        return response
    },
    error => {  
       //发生错误时的响应

        return Promise.reject(error)
    }
)
export default service
