// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const marketContractList = (data) => {
    return service({
        url: '/combo/marketContractList',
        method: 'post',
        data
    })
};

//修改
export const marketContractUpdate = (data) => {
    return service({
        url: '/combo/marketContractUpdate',
        method: 'put',
        data
        
    })
};

//回显
export const getMarketContract = (id) => {
    return service({
        url: '/combo/getMarketContract/'+id,
        method: 'get',
    })
};

//添加
export const addMarketContract = (data) => {
    return service({
        url: '/combo/addMarketContract',
        method: 'post',
        data
    })
};

//删除
export const delMarketContract = (id) => {
    return service({
        url: '/combo/delMarketContract/'+id,
        method: 'put',
    })
};