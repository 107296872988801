// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //房间弹幕管理列表

//列表
export const roomBarrageList = (pageNum,pageSize,id) => {
    return service({
        url: '/gameRoom/roomBarrageList/'+pageNum+'/'+pageSize+'/'+id,
        method: 'get',
    })
};
//删除
export const delRoomBarrage = (id) => {
    return service({
        url: '/gameRoom/delRoomBarrage/'+id,
        method: 'put',
    })
};

//添加
export const addRoomBarrage = (data) => {
    return service({
        url: '/gameRoom/roomBarrageInsert',
        method: 'post',
        data
    })
};

//修改
export const barrageUpdate = (data) => {
    return service({
        url: '/gameRoom/RoomBarrageUpdate',
        method: 'put',
        data
    })
};

//回显
export const getRoomBarrage = (id) => {
    return service({
        url: '/gameRoom/getRoomBarrage/'+id,
        method: 'get',
    })
};