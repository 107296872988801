<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="action" label="动作">
          <template slot-scope="scope">
            {{
              scope.row.action == "1"
                ? "充值指定金额"
                : scope.row.action == "2"
                ? "VIP领取福利"
                : scope.row.action == "3"
                ? "排行榜点赞"
                : scope.row.action == "4"
                ? "邀请分享"
                : scope.row.action == "5"
                ? "投指定数量币"
                : scope.row.action == "6"
                ? "获取指定数量积分"
                : "其余任务完成情况"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="达标数量"> </el-table-column>
        <el-table-column prop="coins" label="奖励金币数量"> </el-table-column>
        <el-table-column prop="image" label="图标">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.image"></el-image>
          </template>
          
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="动作">
            <el-radio-group v-model="info.action">
              <el-radio :label="1">充值指定金额</el-radio>
              <el-radio :label="2">VIP领取福利</el-radio>
              <el-radio :label="3">排行榜点赞</el-radio>
              <el-radio :label="4">邀请分享</el-radio>
              <el-radio :label="5">投指定数量币</el-radio>
              <el-radio :label="6">获取指定数量积分</el-radio>
              <el-radio :label="7">其余任务完成情况</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="达标数量">
            <el-input v-model="info.amount" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="奖励金币数量">
            <el-input v-model="info.coins" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图标">
           
              <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.image" :src="info.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="gettaskDayUpdate()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
        <script>
import { taskDayList, taskDayUpdate, getTaskDay } from "@/api/market/task-day";
export default {
  name: "menu-item",
  data() {
    return {
      fileUrl:"",
      total: 0,
      form: {
        id: "",
        image: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        image: "",
      },
      tableData: [{}],
      dialogFormVisible: false, //弹窗
    };
  },
  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFile";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.gettaskDayList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
        this.info.image = res.data.url;
      },
      beforeAvatarUpload(file) {
        const fileType = file.type === 'image';
        if (!fileType) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        return fileType;
      },
      getFlushed(){
        this.form.pageNum = 1;
        this.form.pageSize = 10;
      this.gettaskDayList();
    },
    //列表
    gettaskDayList() {
      taskDayList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.gettaskDayList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.gettaskDayList();
    },

    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          this.getTaskDay(row.id);
          //回显
          break;

        default:
          break;
      }
    },

    //修改
    gettaskDayUpdate() {
      this.dialogFormVisible = false;
      taskDayUpdate(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.gettaskDayList();
        }
      });
    },
    //回显
    getTaskDay(id) {
      this.dialogFormVisible = true;
      getTaskDay(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>
        
        <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>