<template>
  <div class="stati">
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card class="box-card">
            <h3>报错数</h3>
            <div>
              <i class="el-icon-s-shop" style="color: purple"></i>
              <span>{{errorNum }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <h3>运行房间数</h3>
            <div>
              <i class="el-icon-user-solid" style="color: green"></i>
              <span>{{ roomNum }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <h3>在线人数</h3>
            <div>
              <i class="el-icon-s-flag" style="color: red"></i>
              <span>{{ peopleNum }}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="stati-item">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 第一个标签 -->
        <el-tab-pane label="报错记录" name="first">
          <el-table :data="tableData1" style="width: 100%">
            <el-table-column prop="content" label=" 房间号" width="380">
            </el-table-column>
            <el-table-column prop="createdAt" label="时间" width="580">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown
                  size="mini"
                  @command="(command) => getOperation(command, scope.row)"
                >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-d-arrow-right"
                    >更多</el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      command="maintain"
                      icon="el-icon-circle-check"
                      >开始维修</el-dropdown-item
                    >
                    <el-dropdown-item command="state" icon="el-icon-user"
                      >状态正常</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 第二个标签 -->
        <el-tab-pane label="维修恢复记录" name="second">
          <el-table :data="tableData2" style="width: 100%">
            <el-table-column prop="content" label=" 房间号" width="380">
              <template slot-scope="scope">
                <span>{{scope.row.content.split("报修机器:")[1].substring(0,1)}}号房</span>
              </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="时间" width="380">
            </el-table-column>
            <el-table-column prop="type" label="状态" width="380">
              <template slot-scope="scope">
                  <span v-show="scope.row.type==1">反馈</span>
                  <span v-show="scope.row.type==2">故障</span>
                  <span v-show="scope.row.type==3">维修中</span>
                  <span v-show="scope.row.type==4">维修完成</span>
                  <span v-show="scope.row.type==5">状态正常</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown
                  size="mini"
                  @command="(command) => getOperation(command, scope.row)"
                >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-more-outline"
                    ></el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      command="accomplish"
                      icon="el-icon-circle-check"
                      >维修完成</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="pageNum1"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="pageSize1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total1"
            >
            </el-pagination>
          </div>
          </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { getFaultList,getFaultType,getFaultInfoList,getRoomCount } from "@/api/fault/fault";
export default {
  name: "menu-item",
  data() {
    return {
      activeName: "first",
      errorNum: 0,
      roomNum: 0,
      peopleNum: 0,
      pageSize: 3, //当前页码
      pageNum: 1, //每页显示多少条
      total:0,
      pageSize1: 10, //当前页码
      pageNum1: 1, //每页显示多少条
      total1:0,
      tableData1: [
        {
          id:"",
          createdAt: "",
          content: "",
          type:"",
          status:"",
        },
      ],
      tableData2: [
        {
          id:"",
          createdAt: "",
          content: "",
          type:"",
          status:"",
        },
      ],
    };
  },
  mounted() {
    this.getFaultList1();
    this.getFaultList2();
    this.getRoomCount();
  },
  methods: {
    //头部信息统计
    getRoomCount(){
      getRoomCount().then((res) => {
        
        this.peopleNum=res.data.data.peopleNum;
        this.errorNum=res.data.data.errorNum;
        this.roomNum=res.data.data.roomNum;
      })
    },
    //维修状态
    FaultType(id,type,status){
      getFaultType(id,type,status).then((res) => {
        if(res.data==true){
          //维修成功刷新
          this.getFaultList2();
          this.getFaultList1();
        }
      })
    },
    //获取报错记录
    getFaultList1() {
        getFaultList(2,this.pageNum,this.pageSize).then((res) => {
            this.tableData1 = res.data.data.records;
            this.total = res.data.data.total;
        })
    },
    getFaultList2() {
      getFaultInfoList(this.pageNum1,this.pageSize1).then((res) => {
            this.tableData2 = res.data.data.records;
            this.total1 = res.data.data.total;
        })
    },

    //分页
    handleSizeChange(val) {
      this.pageSize=val;
      this.getFaultList1();
    },
    handleCurrentChange(val) {
      this.pageNum=val;
      this.getFaultList1();
    },
    //分页
    handleSizeChange1(val) {
      this.pageSize1=val;
      this.getFaultList2();
    },
    handleCurrentChange1(val) {
      this.pageNum1=val;
      this.getFaultList2();
    },
    //tab标签页
    handleClick(tab, event) {
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "maintain":
          //开始维修
          this.FaultType(row.id,3,0);
          break;
        case "state":
          //状态正常
          this.FaultType(row.id,5,1);
          break;
          case "accomplish":
          //维修完成
          this.FaultType(row.id,4,0);
          
          break;
          
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 90px;
}
.stati {
  margin-left: 30px;
  width: 100%;
  height: 100%;
}
</style>