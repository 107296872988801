<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="item" size="small" :inline="true">
        <el-form-item label="ID" prop="roleName">
          <el-input
            v-model="item.id"
            placeholder="请输入ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getroomBarrageList"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addVersionLog"
            >添加</el-button
          >
        </el-form-item>
        
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="title" label="弹幕标题"> </el-table-column>
        <el-table-column prop="image" label="弹幕图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.image"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="ID">
            <el-input v-model="form.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="弹幕标题">
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="弹幕图片">
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
             
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
            >
              <img v-if="form.image" :src="form.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="form.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="form.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getbarrageUpdate()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="弹幕标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="弹幕图片">
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.image" :src="info.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getaddRoomBarrage()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
      <script>
import {
  roomBarrageList,
  delRoomBarrage,
  addRoomBarrage,
  getRoomBarrage,
  barrageUpdate,
} from "@/api/game/barrage";
export default {
  name: "menu-item",
  data() {
    return {
      image:"",
      total: 0,
      form: {
        id: "",
      },
      item:{
        id: "",
      },
      info: {
        image: "",
      },
      pageSize: 10,
      pageNum: 1,
      tableData: [{}],
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      fileUrl:"",
    };
  },

  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFile";
  },

  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getroomBarrageList();
    
    
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {

    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.image = res.data.url;
      
    },
    handleAvatarSuccess1(res) {
      this.form.image = res.data.url;
    },
    
    //删除
    getdelRoomBarrage(id) {
      delRoomBarrage(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getroomBarrageList();
        }
      });
    },
    getFlushed(){
      this.item.id="";
      this.pageNum = 1;
      this.pageSize = 10;
      this.getroomBarrageList();
    },
    //列表
    getroomBarrageList() {
      roomBarrageList(this.pageNum, this.pageSize, this.item.id).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getroomBarrageList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getroomBarrageList();
    },

    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          this.getRoomBarrage(row.id);
          //回显
          break;
        case "deleted":
          //状态正常
          this.getdelRoomBarrage(row.id);
          break;
        default:
          break;
      }
    },

    //修改
    getbarrageUpdate() {
      this.dialogFormVisible = false;
      barrageUpdate(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getroomBarrageList();
        }
      });
    },
    //回显
    getRoomBarrage(id) {
      this.dialogFormVisible = true;
      getRoomBarrage(id).then((res) => {
        if (res.data.code == 200) {
          this.form = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = {image: "",};
    },
    //添加方法
    getaddRoomBarrage() {
      this.dialogVisible = false;
      addRoomBarrage(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getroomBarrageList();
        }
      });
    },
  },
};
</script>
      
      <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-button{
  margin-left: 20px;
  margin-top: 10px;
}
</style>