<template>
    <div class="stati">
      <div class="stati-item">
        <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="addSignLog"
              >添加</el-button
            >
            <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
  
        <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="days" label="天数"> </el-table-column>
          <el-table-column prop="coins" label="金币数量"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-dropdown
                size="mini"
                @command="(command) => getOperation(command, scope.row)"
              >
                <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                  >更多</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="update" icon="el-icon-circle-check"
                    >修改</el-dropdown-item
                  >
                  <el-dropdown-item command="deleted" icon="el-icon-user"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="form.pageSize"
            layout="total,  prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
  
        <!-- 弹窗 -->
        <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
          <el-form :model="info">
            <el-form-item label="ID"> 
              <el-input v-model="info.id" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="天数">
              <el-input-number v-model="info.days"  label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="金币数量">
              <el-input-number v-model="info.coins"  label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="info.createdAt"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="更新时间">
              <el-date-picker
                v-model="info.updatedAt"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
           
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="getupdateSign()"
              >确 定 修 改</el-button
            >
          </div>
        </el-dialog>
  
        <!-- 弹窗 -->
        <el-dialog title="添加信息" :visible.sync="dialogVisible">
          <el-form :model="info">
            <el-form-item label="天数">
              <el-input-number v-model="info.days"  label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="金币数量">
              <el-input-number v-model="info.coins"  label="描述文字"></el-input-number>
            </el-form-item>
  
           
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="getaddSign()"
              >确 定 添 加</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </template>
        <script>
  import {
    signListApi,
    addSignApi,
    updateSignApi,
    getSignApi,
    delSignApi
  } from "@/api/market/sign";
  export default {
    name: "menu-item",
    data() {
      return {
        total: 0,
        form: {
          id: "",
          pageSize: 10,
        pageNum: 1,
        },
        info: {},
        
        tableData: [{}],
        dialogFormVisible: false, //弹窗
        dialogVisible: false, //弹窗
      };
    },
  
    mounted() {
      document.title = '后台管理系统|'+this.$route.meta.title ;
      this.getsignList();
    },
    watch: {
      '$route': function(to) {
        document.title = '后台管理系统|'+to.meta.title ;
      }
    },
    methods: {
      cellStyle() {
      return {'text-align': 'center'};
    },

      //删除
      getdelSign(id) {
        delSignApi(id).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              message: "删除成功",
              type: "success",
            });
            this.getsignList();
          }
        });
      },
      getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getsignList();
    },
      //列表
      getsignList() {
        signListApi(this.form).then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
      },
  
      //分页
      handleSizeChange(val) {
        this.form.pageSize = val;
        this.getsignList();
      },
      handleCurrentChange(val) {
        this.form.pageNum = val;
        this.getsignList();
      },
  
      //操作功能
      getOperation(command, row) {
        switch (command) {
          case "update":
            this.getSign(row.id);
            //回显
            break;
          case "deleted":
            //状态正常
            this.getdelSign(row.id);
            break;
          default:
            break;
        }
      },
  
          //修改
          getupdateSign() {
        this.dialogFormVisible = false;
        updateSignApi(this.info).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              message: "数据修改成功",
              type: "success",
            });
            this.getsignList();
          }
        });
      },
      //回显
      getSign(id) {
        this.dialogFormVisible = true;
        getSignApi(id).then((res) => {
          if (res.data.code == 200) {
            this.info = res.data.data;
            this.$notify({
              message: "数据显示成功",
              type: "success",
            });
            this.getsignList();
          }
        });
      },
      //添加dialog
      addSignLog() {
        this.dialogVisible = true;
        this.info = {};
      },
      //添加方法
      getaddSign() {
        this.dialogVisible = false;
        addSignApi(this.info).then((res) => {
          if (res.data.code == 200) {
            this.$notify({
              message: "数据添加成功",
              type: "success",
            });
            this.getsignList();
          }
        });
      },
    },
  };
  </script>
        
        <style scoped>
  .el-card {
    /* margin-bottom: 20px; */
    text-align: center;
    margin-left: 60px;
  }
  .stati-item {
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 40px;
  }
  .stati {
    width: 100%;
    height: 100%;
  }
  .el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
  </style>