<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addBannerLog"
        >添加</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type == "1" ? "轮播图" : scope.row.type == "2" ?"启动图":"" }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="image" label="图片链接">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.image"
            ></el-image>
          </template> 
        </el-table-column>
        <el-table-column prop="url" label="链接地址"> </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="info.type">
              <el-radio :label="1">轮播图</el-radio>
              <el-radio :label="2">启动图</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片链接">
            <el-input v-model="info.image" autocomplete="off"></el-input>
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="info.image"
                :src="info.image"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="链接类型">
            <el-radio-group v-model="info.linkType">
              <el-radio :label="0">空链接</el-radio>
              <el-radio :label="1">外链</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="链接地址">
            <el-input v-model="info.url" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateBanner()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="类型">
            <el-radio-group v-model="info.type">
              <el-radio label="1">轮播图</el-radio>
              <el-radio label="2">启动图</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片链接">
            <el-input v-model="info.image" autocomplete="off"></el-input>
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="info.image"
                :src="info.image"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="链接类型">
            <el-radio-group v-model="info.linkType">
              <el-radio label="0">空链接</el-radio>
              <el-radio label="1">外链</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="链接地址">
            <el-input v-model="info.url" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getaddBanner()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
    <script>
import {
  bannerListApi,
  addBannerApi,
  updateBannerApi,
  getBannerApi,
  delBannerApi,
} from "@/api/site/banner";
export default {
  name: "menu-item",
  data() {
    return {
      fileUrl:"",
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        inviteCode: "",
        mobile: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        id: "",
        image:"",
      },
      tableData: [{}],
    };
  },
  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFile";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getBannerList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {

    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.image = res.data.url;
    },
   
    //添加dialog
    addBannerLog() {
      this.dialogVisible = true;
      this.info = {image:"",};
    },
    //添加方法
    getaddBanner() {
      this.dialogVisible = false;
      addBannerApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getBannerList();
        }
      });
    },

    //删除
    getDelBanner(id) {
      delBannerApi(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getBannerList();
        }
      });
    },
    //修改
    getUpdateBanner() {
      this.dialogFormVisible = false;
      updateBannerApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getBannerList();
        }
      });
    },
    //回显
    getInfoBanner(id) {
      this.dialogFormVisible = true;
      getBannerApi(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getBannerList();
    },
    //列表
    getBannerList() {
      bannerListApi(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getBannerList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getBannerList();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getInfoBanner(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelBanner(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
    
    <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>