// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

// 机器故障
export const getFaultList = (type,pageNum,pageSize) => {
    return service({
        url: '/fault/getFaultList/'+type+'/'+pageNum+'/'+pageSize,
        method: 'get',
    })
};
// 机器故障维修
export const getFaultInfoList = (pageNum,pageSize) => {
    return service({
        url: '/fault/getFaultInfoList/'+pageNum+'/'+pageSize,
        method: 'get',
    })
};
// 机器故障状态
export const getFaultType = (id,type,status) => {
    return service({
        url: '/fault/updateType/'+id+'/'+type+'/'+status,
        method: 'get',
    })
};
//房间故障，运行正常数量，在线人数
export const getRoomCount = () => {
    return service({
        url: '/gameRoom/getRoomCount',
        method: 'get',
    })
};