// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

export const enrollApi = (data) => {
    return service({
        url: '/userBack/enroll',
        method: 'post',
        data
    })
};

export const loginApi = (data) => {
    return service({
        url: '/userBack/login',
        method: 'post',
        data
    })
};