<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
        <el-form-item label="" prop="roleName">
          手机号<el-input
            v-model="form.mobile"
            placeholder="请输入手机号"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="" prop="roleKey">
          邀请人ID<el-input
            v-model="form.inviteOne"
            placeholder="请输入邀请ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="" prop="roleKey">
          用户ID
          <el-input
            v-model="form.uid"
            placeholder="请输入ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="是否主播" prop="roleKey" class="radio">
          <el-radio v-model="form.isPromotion" label="1">是</el-radio>
          <el-radio v-model="form.isPromotion" label="0">否</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getuserList"
            >搜索</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        style="width: 100%"
        :cell-style="cellStyle"
        :header-cell-style="cellStyle"
      >
        <el-table-column prop="id" label="编号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="uid" label="用户信息"> </el-table-column>
        <el-table-column prop="inviteOne" label="邀请人信息"> </el-table-column>
        <el-table-column prop="avatar" label="图像">
          <template slot-scope="scope">
            <el-image
              style="width: 80px; height: 80px"
              :src="scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label=" 手机号"> </el-table-column>
        <el-table-column prop="gold" label="金币"> </el-table-column>
        <el-table-column prop="integral" label="积分"> </el-table-column>
        <el-table-column prop="diamond" label="钻石"> </el-table-column>
        <el-table-column prop="charge" label="充值"> </el-table-column>
        <el-table-column prop="levelCode" label="等级"> </el-table-column>
        <el-table-column prop="createdAt" label="注册时间"> </el-table-column>
        <el-table-column prop="authStatus" label="认证信息">
          <template slot-scope="scope">
            {{
              scope.row.authStatus == 1
                ? "认证"
                : scope.row.authStatus == 2
                ? "未认证"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="isPromotion" label="是否主播">
          <template slot-scope="scope">
            {{
              scope.row.isPromotion == 1
                ? "是"
                : scope.row.isPromotion == 0
                ? "否"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="inviteCode" label="二维码">
          <template slot-scope="scope">
            <!-- 生成二维码 -->
            <vue-qr
              ref="qrCode"
              text="scope.row.inviteCode"
              width="100"
              height="100"
            ></vue-qr>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
                <el-dropdown-item command="account_recharge" icon="el-icon-user"
                  >账号充值</el-dropdown-item
                >
                <el-dropdown-item command="gold_recharge" icon="el-icon-user"
                  >金币充值</el-dropdown-item
                >
                <el-dropdown-item command="recharge_details" icon="el-icon-user"
                  >充值明细</el-dropdown-item
                >
                <el-dropdown-item command="game_record" icon="el-icon-user"
                  >游戏记录</el-dropdown-item
                >
                <el-dropdown-item command="contract_details" icon="el-icon-user"
                  >包机明细</el-dropdown-item
                >
                <el-dropdown-item command="lock_details" icon="el-icon-user"
                  >锁机明细</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>

        


    
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="用户ID">
            <el-input v-model="info.uid" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="info.mobile" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload
              class="avatar-uploader"
              :action="this.fileUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.avatar" :src="info.avatar" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="info.gender">
              <el-radio :label="0">未知</el-radio>
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="info.status">
              <el-radio :label="0">异常</el-radio>
              <el-radio :label="1">正常</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否主播">
            <el-radio-group v-model="info.isPromotion">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateUser()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 账号充值 -->
      <el-dialog title="账号充值" :visible.sync="dialogaccountrecharge">
        <el-form :model="info">
          <el-form-item label="用户ID">
            <el-input v-model="info.uid" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input-number
              v-model="info.number"
              :precision="2"
              :step="0.1"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogaccountrecharge = false">取 消</el-button>
          <el-button type="primary" @click="getAccountrecharge()"
            >确 定 充 值</el-button
          >
        </div>
      </el-dialog>

      <!-- 金币充值 -->
      <el-dialog title="金币充值" :visible.sync="dialoggoldrecharge">
        <el-form :model="info">
          <el-form-item label="用户ID">
            <el-input v-model="info.uid" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="操作">
            <el-radio-group v-model="info.operate">
              <el-radio label="1">增加</el-radio>
              <el-radio label="2">减少</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="info.type">
              <el-radio label="1">金币</el-radio>
              <el-radio label="2">钻石</el-radio>
              <el-radio label="3">积分</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="数量">
            <el-input-number v-model="info.number1"></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialoggoldrecharge = false">取 消</el-button>
          <el-button type="primary" @click="getGoldrecharge()"
            >确 定 充 值</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
  <script>
import {
  userList,
  updateUserApi,
  deleteUserApi,
  UserById,
  accountApi,
  goldApi,
} from "@/api/user/userApi";
// vue2.x
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  name: "menu-item",
  data() {
    return {
      //上传文件
      // :headers="headers"
      // headers: { BackToken: sessionStorage.getItem("token") },
      pages:'',
      fileUrl: "",
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      dialogaccountrecharge: false, //账号充值
      dialoggoldrecharge: false, //金币充值
      total: 0,
      id: "",
      form: {
        mobile: "",
        inviteOne: "",
        uid: "",
        isPromotion: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        uid: "",
        status: "",
        avatar: "",
        number: "",
      },
      tableData: [{}],
    };
  },
  created() {
    this.fileUrl = this.$myGlobalVar + "oss/uploadFile";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getuserList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return { "text-align": "center" };
    },

    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getUser(row.uid);
          break;
        case "deleted":
          this.getDelUser(row.uid);
          break;
        case "account_recharge":
          //账号充值
          this.getAccount(row.uid);
          break;
        case "gold_recharge":
          //金币充值
          this.getGold(row.uid);
          break;
        case "recharge_details":
          //充值明细
          this.rechargeDetails(row.uid);
          break;
        case "game_record":
          //游戏记录
          this.gameRecord(row.uid);
          break;
        case "contract_details":
          //包机明细
          this.contractDetails(row.uid);
          break;
        case "lock_details":
          //锁机明细
          this.lockDetails(row.uid);
          break;
        default:
          break;
      }
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.avatar = res.data.url;
    },
    // beforeAvatarUpload(file) {
    //   const fileType = file.type === "image";
    //   if (!fileType) {
    //     this.$message.error("上传头像图片只能是 JPG 格式!");
    //   }
    //   return fileType;
    // },

    //删除
    getDelUser(id) {
      deleteUserApi(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getuserList();
        }
      });
    },
    //修改
    getUpdateUser() {
      this.dialogFormVisible = false;
      updateUserApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getuserList();
        }
      });
    },
    //回显
    getUser(id) {
      this.dialogFormVisible = true;
      UserById(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    // ===============================================账号充值
    getAccount(id) {
      this.dialogaccountrecharge = true;
      UserById(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },

    getAccountrecharge() {
      this.dialogaccountrecharge = false;
      accountApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "账号充值成功",
            type: "success",
          });
          this.getuserList();
        }
      });
    },
    // ===============================================金币充值
    getGold(id) {
      this.dialoggoldrecharge = true;
      UserById(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },

    getGoldrecharge() {
      this.dialoggoldrecharge = false;
      goldApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "账号充值成功",
            type: "success",
          });
        } else {
          this.$notify({
            message: res.data.message,
            type: "success",
          });
        }
        this.getuserList();
      });
    },
    //===========================================充值明细
    rechargeDetails(id) {
      this.$router.push({ path: "/user/order?uid=" + id });
    },
    //=============================================游戏记录
    gameRecord(id) {
      this.$router.push({ path: "/game/play?uid=" + id });
    },
    //=============================================包机明细
    contractDetails(id) {
      this.$router.push({ path: "/game/contract?uid=" + id });
    },
    //=============================================锁机明细
    lockDetails(id) {
      this.$router.push({ path: "/game/lock?uid=" + id });
    },
    getFlushed() {
      (this.form = {}), (this.form.pageNum = 1);
      this.form.pageSize = 10;
      this.getuserList();
    },
    //列表
    getuserList() {
      userList(this.form).then((res) => {

        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.pages = res.data.data.pages;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getuserList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getuserList();
    },
  },
};
</script>
  
  <style scoped>
.el-card {
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  /* margin-left: 30px; */
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.radio,
.el-button {
  margin-left: 20px;
  margin-top: 10px;
}
</style>