<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
        <el-form-item label="" prop="roleName">
          用户ID<el-input
            v-model="form.uid"
            placeholder="请输入ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="奖励类型" prop="roleKey" class="form-item">
          <el-radio v-model="form.type" label="1">邀请用户</el-radio>
          <el-radio v-model="form.type" label="2">充值奖励</el-radio>
        </el-form-item>
        <el-form-item label="" prop="roleKey" >
          开始时间<el-date-picker
            v-model="form.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="roleKey" >
          结束时间<el-date-picker
            v-model="form.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getanchoAwardList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="编号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="uid" label="用户ID"> </el-table-column>
        <el-table-column prop="fromUid" label="来源用户ID"> </el-table-column>
        <el-table-column prop="chargeId" label=" 充值ID"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? "邀请用户" : scope.row.type == 2 ? "充值奖励":"" }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="奖励金额"> </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
      <script>
import { anchoAwardApi } from "@/api/user/reward";
export default {
  name: "menu-item",
  data() {
    return {
      total: 0,
      form: {
        uid: "",
        type: "",
        startTime: "",
        endTime: "",
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [{}],
    };
  },
  mounted() {
    document.title = '后台管理系统|'+this.$route.meta.title ;
    this.getanchoAwardList();
  },
  watch: {
    '$route': function(to, from) {
      document.title = '后台管理系统|'+to.meta.title ;
    }
  },
  methods: {

    cellStyle() {
      return {'text-align': 'center'};
    },
    getFlushed(){
      this.form={},
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getanchoAwardList();
    },
    //列表
    getanchoAwardList() {
      anchoAwardApi(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getanchoAwardList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getanchoAwardList();
    },
  },
};
</script>
      
      <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.form-item,.el-button{
  margin-left: 20px;
  margin-top: 10px;
}
</style>