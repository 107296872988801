// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const signListApi = (data) => {
    return service({
        url: '/marketSign/marketSignList',
        method: 'post',
        data
    })
};

export const addSignApi = (data) => {
    return service({
        url: '/marketSign/addMarketSign',
        method: 'post',
        data
    })
};

export const updateSignApi = (data) => {
    return service({
        url: '/marketSign/marketSignUpdate',
        method: 'put',
        data
    })
};
export const getSignApi = (id) => {
    return service({
        url: '/marketSign/getMarketSign/'+id,
        method: 'get',
    })
};

export const delSignApi = (id) => {
    return service({
        url: '/marketSign/delMarketSign/'+id,
        method: 'put',
    })
};