<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
          <el-form-item label="ID" prop="roleKey">
        <el-input
          v-model="form.id"
          placeholder="ID"
          clearable
          style="width: 240px"
        />
      </el-form-item>
      <el-form-item label="倍数" prop="roleKey">
        <el-input
          v-model="form.multiple"
          placeholder="请输入倍数"
          clearable
          style="width: 240px"
        />
      </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getmarketLockList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addVersionLog"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="times" label="时长"> </el-table-column>
        <el-table-column prop="coins" label="数量"> </el-table-column>
        <el-table-column prop="multiple" label="倍数"> </el-table-column>
        <el-table-column prop="createdAt" label="开始时间"> </el-table-column>
        
        
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          
          <el-form-item label="时长">
            <el-input-number v-model="info.times"></el-input-number>
          </el-form-item>
          <el-form-item label="数量">
            <el-input-number v-model="info.coins"></el-input-number>
          </el-form-item>
          <el-form-item label="倍数">
            <el-input-number v-model="info.multiple"></el-input-number>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
               value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
               value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getLockUpdate()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
        
          <el-form-item label="时长">
            <el-input-number v-model="info.times"></el-input-number>
          </el-form-item>
          <el-form-item label="数量">
            <el-input-number v-model="info.coins"></el-input-number>
          </el-form-item>
          <el-form-item label="倍数">
            <el-input-number v-model="info.multiple"></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getAddLock()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
    <script>
import {
  marketLockList,
  marketLockUpdate,
  getMarketLock,
  addMarketLock,
  delMarketLock
} from "@/api/game/marketLock";

export default {
  name: "menu-item",
  data() {
    return {
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        chargeType: "",
        type: "",
        pageSize: 20,
        pageNum: 1,
      },
      info: {
        id: "",
      },
      tableData: [{}],
    };
  },

  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.form.multiple=this.$route.query.multiple;
    this.getmarketLockList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = {};
    },
    //添加方法
    getAddLock() {
      this.dialogVisible = false;
      addMarketLock(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getmarketLockList();
        }
      });
    },

    //删除
    getDelLock(id) {
      delMarketLock(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getmarketLockList();
        }
      });
    },
    //修改
    getLockUpdate() {
      this.dialogFormVisible = false;
      marketLockUpdate(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getmarketLockList();
        }
      });
    },
    //回显
    getLock(id) {
      this.dialogFormVisible = true;
      getMarketLock(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    //列表
    getmarketLockList() {
      marketLockList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getmarketLockList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getmarketLockList();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getLock(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelLock(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
    
    <style scoped>
.el-card {
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 90px;
}
.stati {
  margin-left: 30px;
  width: 100%;
  height: 100%;
}

</style>