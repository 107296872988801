// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //游戏投币管理列表

//列表
export const gameCoinsList = (pageNum,pageSize) => {
    return service({
        url: '/gameRoom/gameCoinsList/'+pageNum+'/'+pageSize,
        method: 'get',
        
    })
};