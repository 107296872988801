<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
        <el-form-item label="" prop="roleName">
          用户ID<el-input
            v-model="form.uid"
            placeholder="请输入用户UID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="" prop="roleName">
          ID<el-input
            v-model="form.id"
            placeholder="请输入ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getgameLockList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
        
      </el-form>

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="uid" label="用户ID"> </el-table-column>
        <el-table-column prop="roomId" label="房间ID"> </el-table-column>
        <el-table-column prop="roomTitle" label="房间名称"> </el-table-column>
        <el-table-column prop="roomMultiple" label="倍数"> </el-table-column>
        <el-table-column prop="startAt" label="开始时间"> </el-table-column>
        <el-table-column prop="endAt" label="结束时间"> </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
      <script>
import { gameLockList } from "@/api/game/lock";
export default {
  name: "menu-item",
  data() {
    return {
      total: 0,
      form: {
        id: "",
        uid: "",
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [{}],
    };
  },
  mounted() {
    document.title = '后台管理系统|'+this.$route.meta.title ;
    this.form.uid=this.$route.query.uid;
    this.getgameLockList();
  },
  watch: {
    '$route': function(to, from) {
      document.title = '后台管理系统|'+to.meta.title ;
    }
  },
  methods: {

    cellStyle() {
      return {'text-align': 'center'};
    },
    getFlushed(){
      this.form={},
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getgameLockList();
    },

    //列表
    getgameLockList() {
      gameLockList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getgameLockList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getgameLockList();
    },
  },
};
</script>
      
      <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.el-button{
  margin-left: 20px;
  margin-top: 10px;
}
</style>