import Vue from 'vue'
import App from './App.vue'
import router from './router'
//富文本1
import Vue2Editor from "vue2-editor";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Vue2Editor);
Vue.use(ElementUI)





Vue.config.productionTip = false
import axios from "axios"
axios.defaults.baseURL="api"
Vue.prototype.$http = axios

Vue.prototype.$myGlobalVar = process.env.VUE_APP_BASE_URL;



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
