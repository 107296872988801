<template>
  <div class="main" style="height: 100vh">
    <el-container>
      <el-container>
        <el-aside width="250px">
          <el-menu
            :default-active="this.$route.path"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            @select="handleSelect_2"
            :router="true"
          >
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-user-solid"></i>
                <span>用户管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/user/user">
                  <i class="el-icon-menu"></i>
                  用户管理
                </el-menu-item>
                <el-menu-item index="/user/order">
                  <i class="el-icon-menu"></i>
                  充值订单
                </el-menu-item>
                <el-menu-item index="/user/sign">
                  <i class="el-icon-menu"></i>
                  用户签到
                </el-menu-item>
                <el-menu-item index="/user/coins">
                  <i class="el-icon-menu"></i>
                  用户金币
                </el-menu-item>
                <el-menu-item index="/user/reward">
                  <i class="el-icon-menu"></i>
                  主播奖励
                </el-menu-item>
                
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-s-platform"></i>
                <span>游戏管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/game/room">
                  <i class="el-icon-menu"></i>
                  游戏房间
                </el-menu-item>
                <el-menu-item index="/game/devil">
                  <i class="el-icon-menu"></i>
                  魔鬼城房间
                </el-menu-item>
                <el-menu-item index="/game/contract">
                  <i class="el-icon-menu"></i>
                  游戏包机
                </el-menu-item>
                <el-menu-item index="/game/lock">
                  <i class="el-icon-menu"></i>
                  游戏锁机
                </el-menu-item>
                <el-menu-item index="/game/play">
                  <i class="el-icon-menu"></i>
                  游戏记录
                </el-menu-item>
                <el-menu-item index="/game/coin">
                  <i class="el-icon-menu"></i>
                  游戏投币
                </el-menu-item>
                <el-menu-item index="/game/barrage">
                  <i class="el-icon-menu"></i>
                  房间弹幕
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-present"></i>
                <span>营销管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/market/level">
                  <i class="el-icon-menu"></i>
                  会员等级
                </el-menu-item>
                <el-menu-item index="/market/task-day">
                  <i class="el-icon-menu"></i>
                  每日任务
                </el-menu-item>
                <el-menu-item index="/market/task-long">
                  <i class="el-icon-menu"></i>
                  长期任务
                </el-menu-item>
                <el-menu-item index="/market/charge">
                  <i class="el-icon-menu"></i>
                  充值套餐
                </el-menu-item>
                <el-menu-item index="/market/charge2">
                  <i class="el-icon-menu"></i>
                  连续充值
                </el-menu-item>
                <el-menu-item index="/market/rank">
                  <i class="el-icon-menu"></i>
                  排名奖励
                </el-menu-item>
                <el-menu-item index="/market/sign">
                  <i class="el-icon-menu"></i>
                  签到奖励
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-present"></i>
                <span>站点管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/site/article">
                  <i class="el-icon-menu"></i>
                  文章管理
                </el-menu-item>
                <el-menu-item index="/site/setting">
                  <i class="el-icon-menu"></i>
                  参数设置
                </el-menu-item>
                <el-menu-item index="/site/banner">
                  <i class="el-icon-menu"></i>
                  轮播图
                </el-menu-item>
                <el-menu-item index="/site/app">
                  <i class="el-icon-menu"></i>
                  app版本
                </el-menu-item>
                <el-menu-item index="/site/feedback">
                  <i class="el-icon-menu"></i>
                  意见反馈
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <!-- <el-menu-item index="/fault">
              <i class="el-icon-setting"></i>
              <span slot="title">机器报修</span>
            </el-menu-item> -->

           
          </el-menu>
        </el-aside>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "menu-item",
  data() {
    return {};
  },
  mounted() {
    document.title = this.$route.meta.title || "后台管理系统";
  },
  watch: {
    $route: function (to) {
      document.title = to.meta.title || "后台管理系统";
    },
  },

  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    handSelect(key, keyPath) {
    },
    handleSelect_2(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>


/* 左侧菜单栏主体 */
.el-aside {
  text-align: left;
  line-height: 200px;
  scrollbar-width: none;
}
/* 主菜单 */
.el-menu-vertical-demo {
  /* background-color: #ffffff; */
  height: 100vh;
  margin-top: 0;
}
/* 二级菜单 */
.el-menu-item {
}

.el-main {
  /* background-color: #ffffff; */
  color: #444040;
  /* text-align: center;
        line-height: 160px; */
}
</style>