// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //游戏包机管理列表

//列表
export const ContractList = (data) => {
    return service({
        url: '/gameRoom/gameContractList',
        method: 'post',
        data
    })
};

