// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //直播间管理列表


                                                                                    
//列表(无分页)
export const roomList = () => {
    return service({
        url: '/gameRoom/roomList',
        method: 'get'
        
    })
};
//列表
// export const GameRoomList = (pageNum,pageSize) => {
//     return service({
//         url: '/gameRoom/selectGameRoom/'+pageNum+'/'+pageSize,
//         method: 'get'
        
//     })
// };

export const GameRoomList = (pageNum,pageSize,isShow,status) => {
    return service({
        url: '/gameRoom/selectGameRoom/'+pageNum+'/'+pageSize+"/"+isShow+"/"+status,
        method: 'get'
        
    })
};

//删除
export const delGameRoom = (id) => {
    return service({
        url: '/gameRoom/delGameRoom/'+id,
        method: 'put'
        
    })
};

//修改
export const updateGameRoom = (data) => {
    return service({
        url: '/gameRoom/gameRoomUpdate',
        method: 'put',
        data
    })
};

//回显
export const getGameRoom = (id) => {
    return service({
        url: '/gameRoom/gameRoomById/'+id,
        method: 'get'
        
    })
};