// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

                                                                                    //游戏锁机管理列表

//列表
export const gameLockList = (data) => {
    return service({
        url: '/gameRoom/gameLockList',
        method: 'post',
        data
    })
};