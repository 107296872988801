// file:api.js
// 1、导入axios以及拦截器
import service from '../request'

//列表
export const taskLongList = (data) => {
    return service({
        url: '/marketingManage/taskLongList',
        method: 'post',
        data
    })
};

//修改
export const taskLongUpdate = (data) => {
    return service({
        url: '/marketingManage/taskLongUpdate',
        method: 'put',
        data
        
    })
};

//回显
export const getTaskLong = (id) => {
    return service({
        url: '/marketingManage/getTaskLong/'+id,
        method: 'get',
    })
};

//添加
export const addTaskLong = (data) => {
    return service({
        url: '/marketingManage/addTaskLong',
        method: 'post',
        data
    })
};

//删除
export const delTaskLong = (id) => {
    return service({
        url: '/marketingManage/delTaskLong/'+id,
        method: 'put',
    })
};