// file:api.js
// 1、导入axios以及拦截器
import service from '../request'


//列表
export const rankListApi = (data) => {
    return service({
        url: '/marketingManage/marketRankList',
        method: 'post',
        data
    })
};

export const addRankApi = (data) => {
    return service({
        url: '/marketingManage/addMarketRank',
        method: 'post',
        data
    })
};

export const updateRankApi = (data) => {
    return service({
        url: '/marketingManage/marketRankUpdate',
        method: 'put',
        data
    })
};
export const getRankApi = (id) => {
    return service({
        url: '/marketingManage/getMarketRank/'+id,
        method: 'get',
    })
};

export const delRankApi = (id) => {
    return service({
        url: '/marketingManage/delMarketRank/'+id,
        method: 'put',
    })
};