<template>
  <div>
    <el-container>
      <!-- 顶部导航栏 -->
      <el-header>后台管理系统</el-header>
    
      <el-container>
        <!-- 左侧菜单栏主体 -->
      <el-aside width="200px"><menus></menus></el-aside>
        <!-- 页面主体 -->
        <el-main>
          <!-- 核心这里是路由的出口-->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>




  <!-- =================================== -->
  <!-- <div>
    <el-container> -->
    <!-- 左侧菜单栏主体 -->
      <!-- <el-aside width="200px">
        <menus></menus>
      </el-aside>
      <el-container> -->
        <!-- 顶部导航栏 -->
        <!-- <el-header>后台管理系统</el-header> -->
        <!-- 页面主体 -->
        <!-- <el-main> -->
          <!-- 核心这里是路由的出口-->
          <!-- <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div> -->
</template>
<script>
import menus from "@/components/menus.vue"
export default {
    components: {
        menus
  },

  name: "stati-stics",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.el-header{
  background-color: #3c8dbc;
  color: #333;
  text-align: center;
  line-height: 60px;
  /*  */
  position: relative;
        width: 100%;
        height: 60px;  
}
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #2f4050;
  color: #333;
  text-align: center;
  line-height: 200px;
  /*  */
  display: block;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  /* line-height: 160px; */
  position: absolute;
  left: 200px;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-y: scroll;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>