// file:api.js
// 1、导入axios以及拦截器
import service from '../request'


export const feedbackListApi = (data) => {
    return service({
        url: '/fault/feedbackList',
        method: 'post',
        data
    })
};



export const delFeedbackApi = (id) => {
    return service({
        url: '/fault/delFeedback/'+id,
        method: 'put',
    })
};