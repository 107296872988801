<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addVersionLog"
        >添加</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="cateId" label="文章栏目">
          <template slot-scope="scope">
            {{
              scope.row.cateId == "1"
                ? "关于我们"
                : scope.row.cateId == "2"
                ? "用户协议"
                : scope.row.cateId == "3"
                ? "隐私政策"
                : scope.row.cateId == "4"
                ? "游戏规则"
                : scope.row.cateId == "5"
                ? "会员权益"
                : scope.row.cateId == "6"
                ? "邀请奖励规则"
                : scope.row.cateId == "7"
                ? "排行榜规则"
                : scope.row.cateId == "8"
                ? "充值说明"
                : scope.row.cateId == "9"
                ? "游戏说明"
                : scope.row.cateId == "10"
                ? "最新公告"
                : scope.row.cateId == "11"
                ? "平台公告"
                : scope.row.cateId == "12"
                ? "报修内容"
                : scope.row.cateId == "13"
                ? "房间官方通告"
                : " "
            }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="文章标题"> </el-table-column>
        <el-table-column
          prop="summary"
          label="文章简述"
          show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="image" label="图片链接">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.image"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="文章栏目">
            <el-radio-group v-model="info.cateId">
              <el-radio :label="1">关于我们</el-radio>
              <el-radio :label="2">用户协议</el-radio>
              <el-radio :label="3">隐私政策</el-radio>
              <el-radio :label="4">游戏规则</el-radio>
              <el-radio :label="5">会员权益</el-radio>
              <el-radio :label="6">邀请奖励规则</el-radio>
              <el-radio :label="7">排行榜规则</el-radio>
              <el-radio :label="8">充值说明</el-radio>
              <el-radio :label="9">游戏说明</el-radio>
              <el-radio :label="10">最新公告</el-radio>
              <el-radio :label="11">平台公告</el-radio>
              <el-radio :label="12">报修内容</el-radio>
              <el-radio :label="13">房间官方通告</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文章标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="文章简述">
            <el-input v-model="info.summary" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片链接">
            <el-input v-model="info.image" autocomplete="off"></el-input>
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.image" :src="info.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="文章内容" prop="desc">
            <el-input type="textarea" v-model="info.content"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateArticle()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="文章栏目">
            <el-radio-group v-model="info.cateId">
              <el-radio :label="1">关于我们</el-radio>
              <el-radio :label="2">用户协议</el-radio>
              <el-radio :label="3">隐私政策</el-radio>
              <el-radio :label="4">游戏规则</el-radio>
              <el-radio :label="5">会员权益</el-radio>
              <el-radio :label="6">邀请奖励规则</el-radio>
              <el-radio :label="7">排行榜规则</el-radio>
              <el-radio :label="8">充值说明</el-radio>
              <el-radio :label="9">游戏说明</el-radio>
              <el-radio :label="10">最新公告</el-radio>
              <el-radio :label="11">平台公告</el-radio>
              <el-radio :label="12">报修内容</el-radio>
              <el-radio :label="13">房间官方通告</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文章标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="文章简述">
            <el-input v-model="info.summary" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片链接">
            <el-upload
              class="avatar-uploader"
              :action=this.fileUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="info.image" :src="info.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

         
          <el-form-item label="文章内容" prop="desc">
            <quill-editor
              v-model="info.content"
              ref="myQuillEditor"
              :options="editorOption"
              
            >
            </quill-editor>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getAddArticle()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
    <script>
import {
  articleListApi,
  addArticleApi,
  updateArticleApi,
  getArticleApi,
  delArticleApi,
} from "@/api/site/article";
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    quillEditor,
  },
  name: "menu-item",
  data() {
    return {
      fileUrl:"",
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        inviteCode: "",
        mobile: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        id: "",
        image: "",
      },
      tableData: [{}],
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [
              {
                size: [
                  "12",
                  "14",
                  "16",
                  "18",
                  "20",
                  "22",
                  "24",
                  "28",
                  "32",
                  "36",
                ],
              },
            ], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["image", "video"], // 链接、图片、视频
          ],
        },
        placeholder: "请输入正文",
      },
    };
  },
  created(){
    this.fileUrl=this.$myGlobalVar+"oss/uploadFile";
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getArticleList();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },
    //文件上传
    handleAvatarSuccess(res) {
      this.info.image = res.data.url;
    },
    // beforeAvatarUpload(file) {
    //   const fileType = file.type === "image";
    //   if (!fileType) {
    //     this.$message.error("上传头像图片只能是 JPG 格式!");
    //   }
    //   return fileType;
    // },
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = { image: "" };
    },
    //添加方法
    getAddArticle() {
      this.dialogVisible = false;
      addArticleApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getArticleList();
        }
      });
    },

    //删除
    getDelArticle(id) {
      delArticleApi(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getArticleList();
        }
      });
    },
    //修改
    getUpdateArticle() {
      this.dialogFormVisible = false;
      updateArticleApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getArticleList();
        }
      });
    },
    //回显
    getArticle(id) {
      this.dialogFormVisible = true;
      getArticleApi(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
        }
      });
    },
    getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getArticleList();
    },
    //列表
    getArticleList() {
      articleListApi(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getArticleList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getArticleList();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getArticle(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelArticle(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
    
    <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>