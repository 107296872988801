<template>
  <div class="stati">
    <div class="stati-item">
      <el-form :model="form" size="small" :inline="true">
        <el-form-item label="" prop="roleName">
          ID<el-input
            v-model="form.id"
            placeholder="请输入ID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="" prop="roleKey">
          UID<el-input
            v-model="form.uid"
            placeholder="请输入UID"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getchargeOrder"
            >搜索</el-button
          >
        </el-form-item>
        
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getFlushed"
            >刷新</el-button
          >
        </el-form-item>
        
      </el-form>

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
      
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="uid" label="UID"> </el-table-column>
        <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
        <el-table-column prop="addCoins" label=" 金币数量"> </el-table-column>
        <el-table-column prop="chargePrice" label="付款金额"> </el-table-column>
        <el-table-column prop="chargeType" label="充值类型">
          <template slot-scope="scope">
            {{ scope.row.chargeType == 1 ? "金币" : scope.row.chargeType == 2 ?"钻石":"" }}
          </template>
        </el-table-column>
        <el-table-column prop="payPrice" label="支付金额"> </el-table-column>
        <el-table-column prop="payTime" label="支付时间"> </el-table-column>
        <el-table-column prop="payType" label="支付类型"> </el-table-column>
        <el-table-column prop="payNo" label="支付单号"> </el-table-column>
        <el-table-column prop="deletedAt" label="支付状态">
          <template slot-scope="scope">
            {{ scope.row.payStatus == 0 ? "待支付" : scope.row.payStatus == 1 ?"已支付":"" }}
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
       
      </div>
    </div>
  </div>
</template>
    <script>
import { chargeOrderList } from "@/api/user/order";
export default {
  name: "menu-item",
  data() {
    return {
      total: 0,
      form: {
        id: "",
        uid: "",
        pageSize: 10,
        pageNum: 1,
        multipleSelection: [],
      },

      tableData: [{}],
    };
  },
  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.form.uid=this.$route.query.uid;

    this.getchargeOrder();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {

    cellStyle() {
      return {'text-align': 'center'};
    },
    getFlushed(){
      this.form={},
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getchargeOrder();
    },

    //列表
    getchargeOrder() {
      chargeOrderList(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getchargeOrder();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getchargeOrder();
    },
  },
};
</script>
    
    <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.el-button{
  margin-left: 20px;
  margin-top: 10px;
}
</style>