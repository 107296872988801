<template>
    <div class="stati">
      <div class="stati-item">
        
        <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >
        <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
          <el-table-column prop="id" label="编号"> </el-table-column>
          <el-table-column prop="uid" label="用户ID"> </el-table-column>
          <el-table-column prop="roomId" label="房间ID"> </el-table-column>
          <el-table-column prop="coins" label="数量"> </el-table-column>
          <el-table-column prop="type" label="类型"> 
            <template slot-scope="scope">
              {{ scope.row.type == 1?"投币":scope.row.type == 2?"收入":""  }}
            </template>  
          </el-table-column>
          <el-table-column prop="multiple" label="倍数"> </el-table-column>
          <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
        <script>
  import { gameCoinsList } from "@/api/game/coin";
  export default {
    name: "menu-item",
    data() {
      return {
        total: 0,
        
        pageSize: 20,
        pageNum: 1,
        tableData: [{}],
      };
    },
    
    
    mounted() {
      document.title = '后台管理系统|'+this.$route.meta.title ;
      this.getgameCoinsList();
  },
  watch: {
    '$route': function(to, from) {
      document.title = '后台管理系统|'+to.meta.title ;
    }
  },
    
    methods: {

      cellStyle() {
      return {'text-align': 'center'};
    },
      getFlushed(){
       
      this.getgameCoinsList();
    },
      //列表
      getgameCoinsList() {
        gameCoinsList(this.pageNum,this.pageSize).then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
      },
  
      //分页
      handleSizeChange(val) {
        this.pageSize = val;
        this.getgameCoinsList();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getgameCoinsList();
      },
    },
  };
  </script>
        
 <style scoped>
  .el-card {
    /* margin-bottom: 20px; */
    text-align: center;
    margin-left: 60px;
  }
  .stati-item {
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 90px;
  }
  .stati {
    margin-left: 30px;
    width: 100%;
    height: 100%;
  }
  .el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
  </style>